.router--main--container {
	width: 100%;
	height: calc(100% - 50px);
}

.p-sidebar-mask.p-component-overlay {
	background: none !important;
}

.p-sidebar-icon {
	font-size: large !important;
	margin-right: 10px !important;
}

.p-sidebar-icon:focus,
.p-button:focus,
.p-tree-toggler:focus {
	box-shadow: none !important;
}

.p-sidebar-header {
	width: 60%;
}

.p-sidebar-close {
	display: none !important;
}

.sidebar--main--container {
	width: 33em !important;
	height: calc(100% - 92px) !important;
	top: 5px !important;
	align-items: flex-start;
	z-index: 3001 !important;
}

.sidebar--header--icon--container {
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	justify-content: space-between !important;
	width: 100% !important;
}

.sidebar--header--icon--container--title {
	margin-left: 10px;
	padding-right: 15px;
}

.sidebar--header--icon--container--icons {
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	justify-content: center !important;
}

.p-sidebar-content {
	width: 100%;
}

.sidebar--component--main--container {
	display: flex !important;
	align-items: flex-start !important;
}

/* ******************** */

.p-sidebar-content::-webkit-scrollbar {
	width: 15px !important;
	height: 4px !important;
	background: rgb(255, 255, 255) !important;
}

.p-sidebar-content::-webkit-scrollbar-thumb {
	border-radius: 4px !important;
	height: 10px !important;
	box-shadow: inset 0 0 3px rgb(29, 28, 28) !important;
}

.p-sidebar-content:hover::-webkit-scrollbar {
	background: rgb(255, 255, 255) !important;
}
/* ******************** */

.active--last--used,
.active--menu,
.active--favorites {
	color: #c8d6c5;
}

/* *** SIDEBAR LAYOUT *** */
.layout--sidebar--main--container {
	position: absolute;
	top: 55px !important;
	left: -500px;
	width: 500px;
	transition: all 0.3s;
	/*border: 1px solid red !important;*/
	z-index: 3000;
}

.layout--sidebar--main--container.opened--sidebar {
	left: 0;
}

.sidebar--header--bottom {
	width: 2rem !important;
	height: 2rem !important;
	color: #6c757d !important;
	border: 0 none !important;
	background: transparent !important;
	border-radius: 50% !important;
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s !important;
}
