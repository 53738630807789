/* Estilo de tabla de notificaciones */
.custom-dialog-form .p-dialog-content{
	overflow-y: hidden !important;
	padding: 0.1rem;
	align-content: center;
}
.custom-dialog-form .p-dialog-header{
	padding: 5px 5px 0 5px !important;
}
.custom-dialog-form .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 1rem;
}
.p-datatable .p-datatable-thead > tr > th {
	padding: 0.2rem;
	font-size: 0.8rem;
}
.p-datatable .p-datatable-tbody>tr>td {
    padding: 0.2rem;
	font-size: 0.8rem;
	user-select: text !important;
}
.button--row--container {
	padding: 0 !important;
	margin: 0px !important;
	width: 40px !important;
  }

p-dialog .p-dialog-content:last-of-type {
    padding: 0.5rem !important;
}