.register--screen--main--container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.register--screen--main--container--body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 75%;
	max-width: 400px;
	border-radius: 5px;
	background: rgba(0, 0, 0, 0.3);
}

.alchemy--logo--container {
	display: block;
	width: 100%;
	padding: 0.75rem 7.25rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	background: #232324 !important;
	content: url("../../assets/icons/alchemy.png");
	border-radius: 3px 3px 0 0;
}

.register--screen--main--container--title {
	font-style: italic;
	color: white;
}

.register--screen--main--container--inputs {
	margin: 5px 0 20px 0;
	width: 90%;
}

.register--screen--main--container--inputs--text {
	border: 0.5px solid gray;
	border-radius: 25px;
	margin: 20px 0;
	height: 35px;
	overflow: hidden;
}

.recover--screen--main--container--inputs--link {
	position: relative;
	z-index: inherit;
	text-align: end;
	width: auto;
	float: right;
	margin: 0 10px 20px 0;
	color: rgb(62, 30, 177);
	font-size: 15px;
	text-decoration: underline;
	cursor: pointer;
}
