.p-sidebar-left {
	background: black !important;
}

.sidebar--component--main--container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: calc(100vh - 100px);
	width: 100%;
}

.p-sidebar {
	background-color: transparent !important;
	background-image: linear-gradient(
		180deg,
		#000918 70%,
		#0288d1 160%
	) !important;
}

.p-tree {
	background-color: transparent !important;
	border: none !important;
	color: #e8edf2 !important;
	padding: 1rem 0 0 !important;
}

.p-listbox {
	background-color: transparent !important;
	border: none !important;
}

.p-listbox-item {
	color: #e8edf2 !important;
}

.p-listbox-item :hover {
	color: #10273e !important;
}

.p-tree-selectable {
	width: 100% !important;
}

.star--icon--main--container {
	position: relative;
	z-index: 30;
}

.sidebar--component--main--container--favorites {
	width: 100%;
}
