.favProcess--main--container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	color: white;
	padding: 10px;
	margin: 15px 0;
	position: relative;
	z-index: 1;
}

.favProcess--main--container:hover {
	background-color: white;
	color: black;
	border-radius: 5px;
	cursor: pointer;
}

.favProcess--main--container--data {
	display: flex;
	gap: 15px;
	cursor: pointer;
	width: 100% !important;
}

.favProcess--main--container--data > i {
	padding-top: 1px;
}

.favProcess--main--container--trash {
	cursor: pointer;
	z-index: 564000 !important;
	width: auto !important;
}
