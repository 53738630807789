.home--main--container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	position: relative;
	width: 100%;
	height: 100%;

	background-image: url("../../assets/icons/background--login.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 1000;
}

.home--main--container--modal {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background: rgba(0, 0, 0, 0.5);
	display: none;
}

.home--main--container--modal.visible--modal {
	display: block;
}

.home--main--container--entry {
	margin: 55px;
	border-radius: 10px;
	height: 35px;
	width: 140px;
	overflow: hidden;
}

.home--main--container--table {
	width: 70%;
	border: 0.5px solid gray;
	border-radius: 5px;
	overflow: hidden;
}

.tabview-demo .tabview-custom i,
.tabview-demo .tabview-custom span {
	vertical-align: middle;
}

.tabview-demo .tabview-custom span {
	margin: 0 0.5rem;
}

.tabview-demo .p-button {
	margin-right: 0.25rem;
}

.tabview-demo .p-tabview p {
	line-height: 1.5;
	margin: 0;
}

.closeable-tabs-main-container {
	width: 100% !important;
	padding-right: 20px !important;
}

.closeable-tabs-main-container > div {
	background: transparent !important;
}

.tab {
	background: #1c3b5ccd !important;
	color: white !important;
}
.tab.active {
	background: #1c3b5ccd !important;
	color: white !important;
	border-bottom: 2px solid rgb(106, 206, 223) !important;
}

.closeTab:before {
	color: white !important;
	background: white !important;
}

.closeTab:after {
	color: white !important;
	background: white !important;
}

.closeTab:hover {
	background: #1c3b5ccd !important;
}

.container--tab--list {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 93%;
	padding-top: 2px;
	border-radius: 8px;
}

.home--main--container--tabs--ext {
	height: 20px;
	width: 10px;
	background: transparent;
}

.home--main--container--tabs--content {
	border-radius: 8px;
	width: 100%;
	height: 100%;
	background: rgb(255, 255, 255);
	/*overflow: auto;*/
}

.container-tabs-content-show {
	display: block !important;
}

.container-tabs-content-hide {
	display: none !important;
}

.panel--main--container {
	background: gray;
}

.rc-dyn-tabs-close {
	padding-top: 0px !important;
	font-size: 30px !important;
	padding-left: 7px;
	cursor: pointer;
}

iframe {
	height: 80vh !important;
	overflow: auto !important;
}

.rc-dyn-tabs-panellist {
	background: var(--surface-b);
	height: 95%;
}

.rc-dyn-tabs-tablist.rc-dyn-tabs-ltr>.rc-dyn-tabs-tab>.rc-dyn-tabs-title{
	color: var(--text-color);
}

.rc-dyn-tabs-tablist .rc-dyn-tabs-tab.rc-dyn-tabs-selected>.rc-dyn-tabs-title{
	font-weight: bold;
	background-color: var(--surface-b)
}


.rc-dyn-tabs-tablist .rc-dyn-tabs-tab>.rc-dyn-tabs-title{
	padding-right: 2.5rem;
}

.rc-dyn-tabs-tablist > .rc-dyn-tabs-tab > .rc-dyn-tabs-close{
	top: -7px;
}

#rc-dyn-tabs-p-idNotifications{
	overflow: auto;
	height: 100%;
}
