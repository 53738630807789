.login--screen--main--container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.login--screen--main--container--inputs--reset {
	text-align: center;
	/* margin: 5px 10px;
	font-size: 14px; */
	cursor: pointer;
	color: rgb(24, 128, 238);
}

.alchemy--logo--container {
	display: block;
	width: 100%;
	padding: 0.75rem 1.25rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	background: #232324 !important;
	content: url("../../assets/icons/alchemy.png");
	border-radius: 3px 3px 0 0;
}

.alchemy--logo--container > img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

/* **************** */
.form-demo .card {
	min-width: 450px;
}
.form-demo .card form {
	margin-top: 2rem;
}
.form-demo .card .p-field {
	margin-bottom: 1.5rem;
}
@media screen and (max-width: 960px) {
	.form-demo .card {
		width: 80%;
	}
}
@media screen and (max-width: 640px) {
	.form-demo .card {
		width: 100%;
		min-width: 0;
	}
}

/* ********************************************** */
/* ********************************************** */
/* ********************************************** */
/* ********************************************** */
.upload--files--add--main--container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding-left: 10px;
}

/* *********************************************** */
.upload--files--add--main--container--frame {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	color: white;
	width: 100px;
	height: 100px;
	position: relative;
}
/* *********************************************** */
.upload--files--add--main--container--username {
	width: calc(100% - 100px);
	text-align: center;
}

.upload--files--add--main--container--username--title {
	font-size: 30px;
	font-weight: 600;
	margin: 5px;
}
/* *********************************************** */

.upload--files--add--main--container--frame > input {
	z-index: 5000;
	background: transparent;
	color: #ffffff00;
	background: #ffffff00;
}

/* ****************************************** */
.upload--files--add--main--container--frame--input::-webkit-file-upload-button {
	visibility: hidden;
}
.upload--files--add--main--container--frame--input::before {
	position: absolute;
	left: 0;
	top: 0;
	content: "";
	display: inline-block;
	border-radius: 3px;
	/* padding: 5px 8px; */
	outline: none;
	white-space: nowrap;
	-webkit-user-select: none;
	cursor: pointer;
	font-weight: 700;
	font-size: 10pt;
	height: 80px;
	/* border: 5px solid purple; */
	width: 80px;
}

.upload--files--add--main--container--frame--input:active::before {
	border: none;
	outline: none;
}

.upload--files--add--main--container--frame--input:active::after {
	border: none;
	outline: none;
}

.upload--files--add--main--container--frame--input:active {
	border: none;
	outline: none;
}

.upload--files--add--main--container--mask {
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 90px;
	width: 90px;
	z-index: 5;
	/* border: 1px solid blue; */
}

.upload--files--add--main--container--mask--image {
	height: 100%;
	width: 100%;
	/* border: 1px solid green; */
}

.p-dialog-header {
	margin: 0 !important;
	padding: 10px 10px 0 5px !important;
}

.upload--files--add--main--container--username--text {
	color: gray;
}
