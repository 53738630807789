.confirm--screen--main--container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	z-index: 1;
	position: relative;
	background: rgb(2 136 209);
}

.confirm--screen--main--container--inputs--link {
	position: relative;
	text-align: end;
	margin: 5px 10px;
	font-size: 14px;
	z-index: inherit;
}

.alchemy--logo--container {
	display: block;
	width: 100%;
	padding: 0.75rem 7.25rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	background: #232324 !important;
	content: url("../../assets/icons/alchemy.png");
	border-radius: 3px 3px 0 0;
}

.alchemy--logo--container > img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

/* **************** */
.form-demo .card {
	min-width: 450px;
}
.form-demo .card form {
	margin-top: 2rem;
}
.form-demo .card .p-field {
	margin-bottom: 1.5rem;
}
@media screen and (max-width: 960px) {
	.form-demo .card {
		width: 80%;
	}
}
@media screen and (max-width: 640px) {
	.form-demo .card {
		width: 100%;
		min-width: 0;
	}
}

/* ********************************************* */
/* ********************************************* */
/* ********************************************* */
.App-particles__container {
	overflow: hidden;
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
}
.App-particles__container canvas {
	transition: 0.2s all ease-in-out;
	z-index: 0;
	height: 100%;
	width: 100%;
}
