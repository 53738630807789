.lastUsed--main--container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	color: white;
	padding: 10px;
	margin: 15px 0;
}

.lastUsed--main--container:hover {
	background-color: white;
	color: black;
	border-radius: 5px;
	cursor: pointer;
}

.lastUsed--main--container--data {
	display: flex;
	gap: 15px;
	cursor: pointer;
	width: 100% !important;
}

.lastUsed--main--container--data > i {
	padding-top: 1px;
}

.lastUsed--main--container--trash {
	cursor: pointer;
}
