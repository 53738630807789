.footer--main--container {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1000;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	height: 40px;
	width: 100%;
	background-color: transparent;
	background-image: linear-gradient(150deg, #000918 55%, #0288d1 100%);
	box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 80%);
	padding-right: 20px;
}

.footer--main--container--image {
	height: 20px !important;
	width: 140px !important;
}

.footer--main--container--image > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
