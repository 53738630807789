.header--main--container,
.header--main--container-index {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 50px;
	width: 100%;
	background-color: transparent;
	background-image: linear-gradient(150deg, #000918 55%, #0288d1 100%);
	box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 80%);
	z-index: 1015;
}

.header--main--container--toogle {
	width: 33%;
}

.header--main--container--logo {
	width: 33%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header--main--container--logo--image {
	max-width: 170px;
	padding-top: 5px;
}

.header--main--container--logo--image > img {
	width: 100px;
	height: 22px;
}

.header--main--container--icons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 33%;
}

.header--main--container--logout {
	display: flex;
	margin: 0 20px;
}

.header--main--container--logout > i {
	color: white;
}

.header--main--container--notifications--count--messages {
	position: absolute;
	bottom: -5px;
	left: -13px;
	font-size: 20px;
	color: red;
	z-index: 900000000;
}

.p-splitbutton-defaultbutton {
	width: 170px !important;
	background: transparent !important;
	border: none !important;
	padding: 0 !important;
	margin: 0 !important;
}

.p-splitbutton-menubutton {
	background: transparent !important;
	border: none !important;
	padding: 0 !important;
	margin: 0 !important;
}

.select-product-button {
	background: transparent !important;
	border: none !important;
	outline: transparent !important;
	margin-right: 30px;
}

.select-product-button:focus {
	outline: 0 none !important;
	outline-offset: 0 !important;
	box-shadow: none !important;
}

.classIconBell {
	font-size: 25px;
	padding-right: 15px;
}

/* .p-datatable-thead {
	display: none !important;
} */

.overlaypanel-notification {
	max-width: 400px !important;
}

.btn-profile-menu {
	background: transparent !important;
	border: none !important;
}

#label--btn--name {
	width: max-content !important;
}

.icon--element--menu {
	color: rgb(103, 103, 103) !important;
	font-size: 20px;
}

#overlay_panel {
	z-index: 1015 !important;
}

.header--languages--container {
	text-align: left;
	padding-left: 12px;
}

.header--languages--container > img {
	cursor: pointer;
	margin: 0 5px;
}
